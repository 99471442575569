@keyframes pulse-secondary {
	0% {
		/* transform: scale(1.05); */
		box-shadow: 0 0 0 0 rgba(246, 204, 76, 0.7);
	}
	
	70% {
		/* transform: scale(1); */
		box-shadow: 0 0 0 10px rgba(246, 204, 76, 0);
	}
	
	100% {
		/* transform: scale(1.05); */
		box-shadow: 0 0 0 0 rgba(246, 204, 76, 0);
	}
}

