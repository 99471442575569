body {
	margin: 0;
	font-family: Verdana, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	max-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	flex: 1 0 100%;
	display: flex;
	flex-direction: column;
}

.flex {
	display: flex;
	align-items: center;
}
.flex-vert {
	display: flex;
	flex-direction: column;
}
.flex-grow {
	flex: 1 0 0px;
}